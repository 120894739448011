<template>
    <!-- <section class="section tmp-banner no-bg">
		<div class="container">
			<div class="column-wrapper">
				<div class="column intro-column">
					<h1 class="block-title">Nurture your mind</h1>
					<span class="block-subtitle"
						>A selection of inspiring interviews, articles and essays</span
					>
				</div>
			</div>
		</div>
	</section> -->

    <HeroPage v-if="pageData" :data="pageData" heroType="center" heroSize="small"></HeroPage>

    <ContentGridFilterCollection :paging="paging" :collectionFilter="collectionFilter" collectionFilterType="buttons"
        contentType="blog" />
</template>

<script>
import { fetchPageBySlug } from "@streampac.io/chef_sp_1";

import ContentGridFilterCollection from "@/components/ContentGridFilterCollection";
import HeroPage from "@/components/HeroPage";

export default {
    name: "Blog",
    inject: ["isAuthenticated"],
    data() {
        return {
            pageData: null,
            paging: {
                pageNumber: 1,
                pageSize: 12,
            },
            collectionFilter: ["Blog Type"],
        };
    },
    components: {
        HeroPage,
        ContentGridFilterCollection,
    },
    created() {
        this.fetchPage();
    },
    methods: {
        fetchPage() {
            fetchPageBySlug(this.isAuthenticated, "blog").then(
                (response) => {
                    //console.log(response);
                    this.pageData = response;
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
